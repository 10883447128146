import React, {useEffect, useState} from 'react';
import {Modal, Box, Tabs, Tab, Typography, TextField, Button} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import PropTypes from 'prop-types';
import api from '../../API';
import {useNavigate, useLocation} from 'react-router-dom';
import GoogleDriveButton from '../GDrive/GoogleDriveButton';
import GoogleDrivePicker from '../GDrive/GoogleDrivePicker';
import {useAuth} from "../../contexts/AuthContext";

const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#ef798a', // Change this to your tab color
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#ef798a', // Change this to your tab indicator color
                },
            },
        },
    },
});

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const ImportModal = ({open, handleClose}) => {
    const {userId} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [value, setValue] = useState(0);
    const [gDriveUrl, setGDriveUrl] = useState('');

    const uploadLocalData = () => {
        navigate('/dropbox');
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleGDriveUrlChange = (event) => {
        setGDriveUrl(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            const response = await api.post('data/import', {url: gDriveUrl});

            if (response.status === 200) {
                console.log('URL submitted successfully');
                handleClose(); // Close the modal after successful submission
            } else {
                console.error('Failed to submit URL');
            }
        } catch (error) {
            console.error('Error submitting URL:', error);
        }
    };

    const handleFolderSelect = async (folderId) => {
        try {
            const response = await api.post(`gdrive/folder`, {folder_id: folderId});

            if (response.status === 200) {
                console.log('URL submitted successfully');
                handleClose(); // Close the modal after successful submission
            } else {
                console.error('Failed to submit URL');
            }
        } catch (error) {
            console.error('Error submitting URL:', error);
        }
    };


    const [accessToken, setAccessToken] = useState(null);
    const [folderId, setFolderId] = useState(null);


    const handleGoogleDriveAuth = async () => {
        try {
            const response = await api.get(`gdrive/get_access_token`);
            console.warn(response);
            if (response.status === 200) {
                setAccessToken(response.data.access_token);
            } else {
                console.error('Failed to get token');
            }
        } catch (error) {
            console.error('Failed to get token', error);
        }
    };


    const handleFolderSelected = (selectedFolderId) => {
        setFolderId(selectedFolderId);
        console.log('Selected Folder ID:', selectedFolderId);
    };

    useEffect(() => {
        handleFolderSelect(folderId)
    }, [folderId]);

    useEffect(() => {
        handleGoogleDriveAuth();
    }, []);


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {xs: '90%', sm: '70%', md: '50%', lg: '40%', xl: '30%'},
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    height: {xs: 'auto', md: 300},
                    p: 3,
                }}
            >
                <Typography id="modal-title" variant="h6" component="h2">
                    Import your document
                </Typography>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <ThemeProvider theme={theme}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="GDrive" {...a11yProps(0)} />
                            <Tab label="Local" {...a11yProps(1)} />
                            <Tab label="SharePoint" {...a11yProps(2)} />
                        </Tabs>
                    </ThemeProvider>
                </Box>
                <TabPanel value={value} index={0}>
                    <TextField
                        fullWidth
                        label="GDrive URL"
                        variant="outlined"
                        value={gDriveUrl}
                        onChange={handleGDriveUrlChange}
                    />

                    <Button
                        variant="contained"
                        sx={{
                            marginTop: 2,
                            background: 'linear-gradient(45deg, #ef798a 30%, #674f87 90%)',
                            color: 'white',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #ef798a 30%, #674f87 90%)',
                            },
                        }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                    {/* Google Drive Authentication Button */}
                    <GoogleDriveButton onAuthenticated={handleGoogleDriveAuth} userId={userId}/>

                    {/* If authenticated, show the Google Picker for folder selection */}
                    {accessToken && (
                        <GoogleDrivePicker
                            accessToken={accessToken}
                            onFolderSelected={handleFolderSelected}
                        />
                    )}

                    {/* Display selected folder ID
                    {folderId && <p>Selected Folder ID: {folderId}</p>}*/}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Typography>
                        <Button
                            variant="contained"
                            sx={{
                                marginTop: 2,
                                background: 'linear-gradient(45deg, #ef798a 30%, #674f87 90%)',
                                color: 'white',
                                '&:hover': {
                                    background: 'linear-gradient(45deg, #ef798a 30%, #674f87 90%)',
                                },
                            }}
                            onClick={uploadLocalData}
                        >
                            upload data from local
                        </Button>
                    </Typography>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Typography>SharePoint content goes here.</Typography>
                </TabPanel>
            </Box>
        </Modal>
    );
};

ImportModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default ImportModal;
