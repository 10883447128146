import React, {useState, useEffect} from 'react';
import Button from "@mui/material/Button";

const GoogleDriveButton = ({onAuthenticated, userId}) => {
    const [popup, setPopup] = useState(null);

    const handleGoogleDriveAuth = () => {
        const apiURL = process.env.REACT_APP_BACKEND_API_URL;
        const authUrl = `${apiURL}/gdrive/auth/google?user_id=${encodeURIComponent(userId)}`;
        const width = 500;
        const height = 600;
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);

        const newPopup = window.open(
            authUrl,
            'Google Drive Authentication',
            `width=${width},height=${height},top=${top},left=${left}`
        );
        setPopup(newPopup);
    };

    useEffect(() => {
        if (popup) {
            const popupInterval = setInterval(() => {
                if (popup.closed) {
                    clearInterval(popupInterval);
                    console.log('Popup closed, you can now fetch the token from the backend.');
                    onAuthenticated();
                }
            }, 500);
        }
    }, [popup, onAuthenticated]);

    return (
        <Button
            variant="contained"
            sx={{
                marginTop: 2,
                background: 'linear-gradient(45deg, #ef798a 30%, #674f87 90%)',
                color: 'white',
                '&:hover': {
                    background: 'linear-gradient(45deg, #ef798a 30%, #674f87 90%)',
                },
                ml: 1,
            }}
            onClick={handleGoogleDriveAuth}
        >
            Connect to Google Drive
        </Button>
    );
};

export default GoogleDriveButton;
