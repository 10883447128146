import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {AuthProvider} from './contexts/AuthContext';
import './index.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Help from './pages/Help/Help';
import {ThemeProvider} from '@mui/material/styles';
import {dashboardTheme} from './dashboardTheme';
import {NavbarProvider} from './components/Navbar/NavbarContext';
import {AssistProvider} from './components/ai-assist/AssistContext';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Dashboard from './pages/AR-Dashboard/Dashboard';
import TagDatatable from './pages/Tags/TagDatatable';
import Status from './pages/status/status';
import Logs from './pages/Log/logs';
import DropBox from './pages/AR-Dashboard/DropBox';
import './components/Language/i18n';
import UserProfile from './pages/UserProfile/userProfile';
import {Navigate} from "react-router-dom";

const Root = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem('isLoggedIn') === 'true'
    );

    const handleLogin = () => {
        setIsLoggedIn(true);
    };

    const ProtectedRoute = ({isLoggedIn, children}) => {
        if (!isLoggedIn) {
            return <Navigate to="/login"/>;
        }
        return children;
    };

    return (
        <BrowserRouter>
            <AuthProvider>
                <NavbarProvider>
                    <AssistProvider>
                        <ThemeProvider theme={dashboardTheme}>
                            <Routes>
                                <Route path="/login" element={<Login onLogin={handleLogin}/>}/>
                                <Route path="/" element={<App isLoggedIn={isLoggedIn} handleLogin={handleLogin}/>}>
                                    <Route path=""
                                           element={<ProtectedRoute isLoggedIn={isLoggedIn}><Home/></ProtectedRoute>}/>
                                    <Route path="documents" element={<ProtectedRoute
                                        isLoggedIn={isLoggedIn}><Dashboard/></ProtectedRoute>}/>
                                    <Route path="help"
                                           element={<ProtectedRoute isLoggedIn={isLoggedIn}><Help/></ProtectedRoute>}/>
                                    <Route path="tags" element={<ProtectedRoute isLoggedIn={isLoggedIn}><TagDatatable/></ProtectedRoute>}/>
                                    <Route path="drive" element={<ProtectedRoute
                                        isLoggedIn={isLoggedIn}><Status/></ProtectedRoute>}/>
                                    <Route path="logs"
                                           element={<ProtectedRoute isLoggedIn={isLoggedIn}><Logs/></ProtectedRoute>}/>
                                    <Route path="dropbox" element={<ProtectedRoute
                                        isLoggedIn={isLoggedIn}><DropBox/></ProtectedRoute>}/>
                                    <Route path="user_profile" element={<ProtectedRoute
                                        isLoggedIn={isLoggedIn}><UserProfile/></ProtectedRoute>}/>
                                </Route>
                            </Routes>
                        </ThemeProvider>
                    </AssistProvider>
                </NavbarProvider>
            </AuthProvider>
        </BrowserRouter>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <Root/>
    </React.StrictMode>,
    document.getElementById('root')
);