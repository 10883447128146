import React, {createContext, useContext, useState, useEffect} from 'react';
import {login as apiLogin, logout as apiLogout} from '../services/authService';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);
    const [metaUser, setMetaUser] = useState(null);
    const [role, setRole] = useState(null);
    const [error, setError] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperuser, setIsSuperuser] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [isMaintainer, setIsMaintainer] = useState(false);

    useEffect(() => {
        const savedUser = JSON.parse(localStorage.getItem('user'));
        const savedMetaUser = JSON.parse(localStorage.getItem('metaUser'));
        const savedUserId = localStorage.getItem('userId');
        const savedRole = localStorage.getItem('role');
        if (savedUser && savedRole) {
            setUser(savedUser);
            setRole(savedRole);
            setMetaUser(savedMetaUser);
            setUserId(savedUserId);
            updateRoleFlags(savedRole);
        }
    }, []);

    const updateRoleFlags = (customRole) => {
        setIsAdmin(customRole === 'admin');
        setIsSuperuser(customRole === 'superuser');
        setIsUser(customRole === 'user');
        setIsMaintainer(customRole === 'maintainer');
    };

    const login = async (formData) => {
        try {
            const {
                userRefreshToken,
                userAccessToken,
                realm,
                username,
                userInfo,
                profileId,
                profileInfo,
                userRole
            } = await apiLogin(formData, setError);

            if (!userInfo) {
                console.error('No user info');
                return false;
            }
            setUser(userInfo);
            if (!profileId) {
                console.error('No user info');
                return false;
            }
            setUserId(profileId);
            if (!profileInfo) {
                console.error('No user info');
                return false;
            }
            setMetaUser(profileInfo);
            if (!userRole) {
                console.error('No user role');
                return false;
            }

            const intersection = userRole.filter(item => ['user', 'maintainer', 'admin', 'superuser'].includes(item));
            if (!intersection) {
                console.error('Login failed:');
                return false;
            }
            let customRole = intersection[0];
            setRole(customRole);
            updateRoleFlags(customRole);

            localStorage.setItem('user', JSON.stringify(userInfo));
            localStorage.setItem('accessToken', userAccessToken);
            localStorage.setItem('refreshToken', userRefreshToken);
            localStorage.setItem('realm', realm);
            localStorage.setItem('username', username);
            localStorage.setItem('userId', profileId);
            localStorage.setItem('metaUser', JSON.stringify(profileInfo));
            localStorage.setItem('role', customRole);
            localStorage.setItem('isLoggedIn', 'true');
            setError(null);
            return true;
        } catch (error) {
            console.error('Login failed:', error);
            setError('Login failed. Please check your credentials.');
            return false;
        }
    };

    const logout = async () => {
        try {
            const logoutStatus = await apiLogout(setError());
            if (logoutStatus) {
                localStorage.clear();
                setUser(null);
                setUserId(null);
                setMetaUser(null);
                setRole(null);
                setIsAdmin(false);
                setIsSuperuser(false);
                setIsUser(false);
                setIsMaintainer(false);
            }
            return false;
        } catch (error) {
            console.error('Logout failed:', error);
            return false;
        }
    };

    const value = {
        user,
        userId,
        metaUser,
        role,
        isAdmin,
        isSuperuser,
        isUser,
        isMaintainer,
        login,
        logout,
        error,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
