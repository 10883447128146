import React, {useState, useEffect, useRef} from 'react';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import './Image-grid.css';
import {
    Stack,
    CircularProgress,
    Divider
} from '@mui/material';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import DisplayDoc from '../Doc-Display/doc-display';
import {useNavbar} from '../../components/Navbar/NavbarContext';
import api from "../../API";
import Pagination from '../../components/Pagination/Pagination';
import CustomSnackbar from '../../components/SnackBar/Snackbar';
import eventEmitter from './EvenEmitter';
import ImageGridContainer from "../../components/Document/ImageGridContainer";
import FilterContent from "../../components/Filters/FilterContent"
import {useLocation} from 'react-router-dom';
import ChatDrawer from '../../components/ChatBox/ChatDrawer'
import DeleteConfirmationDialog from '../../components/Conformations/DeleteConfirmationDialog';
import {useTranslation} from 'react-i18next';
import {FilterStrings, UnknownStrings} from '../../components/RandomTexts/FilterStrings';
import FolderMap from '../../components/FolderMap/FolderMap';

export default function Dashboard() {
    const {setCollapsed} = useNavbar();
    const location = useLocation();
    const defaultDocType = (location.state && location.state.defaultDocType) ? location.state.defaultDocType : {};
    setCollapsed(false);
    const [params, setParams] = useState({
        docIds: [],
        tadIDs: [],
        docTypes: [],
        contentSearch: null,
        aiMessage: null,
        latest: true
    });
    const [customFields, setCustomFields] = useState(["d.document_id as document_id", "d.document_super_name as document_super_name", "d.document_sub_name as document_sub_name", "d.document_thumbnail as document_thumbnail"]);
    const [selectDoc, setSelectDoc] = useState(null);
    const [selectDocId, setSelectDocId] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [docs, setDocs] = useState([]);
    const [docIds, setDocIds] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [previous, setPrevious] = useState(null);
    const [next, setNext] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [limit, setLimit] = useState(14);
    const [quietMode, setQuietMode] = useState(null);
    const [snackbarOpen, setSnackBarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [rootFolderId, setRootFolderId] = useState(0);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [selectedDocumentIds, setSelectedDocumentsIds] = useState([]);
    const [isFolderType, setIsFolderType] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState([false, null]);
    const [downloadLoading, setDownloadLoading] = useState([false, null]);
    const [openDrawerChat, setOpenDrawerChat] = React.useState(false);
    const chatContentRef = useRef(null);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [delDocumentId, setDelDocumentID] = useState(null);
    const [reloadDocs, setReloadDocs] = useState(false);
    const [paginationList, setPaginationList] = useState([14, 20, 50]);
    const programmaticUpdate = useRef(false);
    const [aiLoading, setAiLoading] = useState(false);
    const [folderPath, setFolderPath] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        if (chatContentRef.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, [messages]);

    const handleDocumentClick = (event) => {
        event.preventDefault();
        const documentId = event.target.getAttribute('data-doc-id');
        // console.log('Document clicked:', documentId);
        setSelectDocId(documentId);
    };

    const handleItem = (doc) => {
        setSelectDocId(doc.document_id);
    };
    const fetchSingleDoc = async (params) => {
        try {
            const response = await api.get('data/documents', {params});
            if (response.status !== 200) {
                console.error('Files loading error', response.status);
                return false;
            }
            const documentData = response.data.results;
            setSelectDoc(documentData);
            return true;
        } catch (error) {
            console.error('Error fetching Versions:', error);
            return false;
        }
    };

    useEffect(() => {
        const loadDocument = async () => {
            if (selectDocId && !redirect) {
                const query_params = {document_id: selectDocId, latest: false};
                const success = await fetchSingleDoc(query_params);
                if (success) {
                    setRedirect(true);  // Only set redirect if fetchSingleDoc was successful
                }
            }
        };
        loadDocument();
    }, [selectDocId]);

    const folderNavigateTo = (folderID, folderName) => {
        const updatedPath = [...folderPath];
        updatedPath.push({folderID: folderID, folderName: folderName});
        setFolderPath(updatedPath);
        handleParentChange(folderID);
    };

    const folderPathSlicer = (folderID) => {
        if (folderID === 0) {
            setFolderPath([]);
            return;
        }
        const index = folderPath.findIndex(folder => folder.folderID === folderID);

        if (index !== -1) {
            const updatedPath = folderPath.slice(0, index + 1);
            setFolderPath(updatedPath);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    useEffect(() => {
        api.post('data/clear_cache').then(r => {
        });
    }, []);

    const handleSearch = async () => {
        setAiLoading(true);
        if (message.trim() === '') return;
        const newMessages = [...messages, {from: 'user', text: message}];
        setMessages(newMessages);
        const query_params = params;
        query_params.inputMessage = message;
        query_params.FileIds = selectedDocumentIds;
        setParams(query_params);
        try {
            const response = await api.post('ai/ai_group_file_search', params, {timeout: 2 * 60000});
            if (response.status === 200) {
                let updatedMessages = [...messages];
                if (response.data.aiResponseType === 'chat' || response.data.aiResponseType === 'summary') {
                    setDocIds(response.data.document_ids);
                    updatedMessages = [
                        ...updatedMessages,
                        {from: 'user', text: message},
                        {from: 'backend', text: response.data.response}
                    ];
                } else if (response.data.aiResponseType === 'filter') {
                    setDocIds(response.data.response.document_ids);
                    const randomFilterStrings = FilterStrings[Math.floor(Math.random() * FilterStrings.length)];
                    updatedMessages = [
                        ...updatedMessages,
                        {from: 'user', text: message},
                        {from: 'backend', text: randomFilterStrings}
                    ];
                } else if (response.data.aiResponseType === 'unknown') {
                    const randomUnknownStrings = UnknownStrings[Math.floor(Math.random() * UnknownStrings.length)];
                    updatedMessages = [
                        ...updatedMessages,
                        {from: 'user', text: message},
                        {from: 'backend', text: randomUnknownStrings}
                    ];
                }
                setMessages(updatedMessages);
                setMessage('');
            }
        } catch (err) {
            console.error('Error ai call:', err);
        }
        setAiLoading(false);
    };

    const clearDocIds = () => {
        setDocIds([]);
    }

    useEffect(() => {
        if (selectedDocuments.length > 0) {
            handleDrawerChatOpen();
        }/* else {
            handleDrawerChatClose();
        }*/
    }, [selectedDocuments]);

    const handleDrawerChat = () => {
        if (openDrawerChat) {
            setMessages([]);
        }
        setOpenDrawerChat(!openDrawerChat);
    }

    const handleDrawerChatClose = () => {
        setOpenDrawerChat(false);
    };

    const handleDrawerChatOpen = () => {
        setOpenDrawerChat(true);
    };

    const handleItemView = () => {
        setIsFolderType(false);
        handleDocsReload();
    };

    const handleFolderType = () => {
        setIsFolderType(true);
        handleDocsReload();
    };

    const handleCheckboxChange = (doc) => {
        if (Array.isArray(selectedDocuments)) {
            setSelectedDocuments(prevSelectedDocuments => {
                if (prevSelectedDocuments.some(temp => temp.document_id === doc.document_id)) {
                    setSelectedDocumentsIds(prevSelectedDocumentIds =>
                        prevSelectedDocumentIds.filter(id => id !== doc.document_id)
                    );
                    return prevSelectedDocuments.filter(temp => temp.document_id !== doc.document_id);
                } else {
                    setSelectedDocumentsIds(prevSelectedDocumentIds =>
                        [...prevSelectedDocumentIds, doc.document_id]
                    );
                    return [...prevSelectedDocuments, doc];
                }
            });
        }
    };
    const fetchDocs = async (url, params) => {
        try {
            setIsLoading(true);
            const response = await api.get(url, {params});
            setIsLoading(false);
            if (response.status !== 200) {
                console.error('Files loading error', response.status);
                return false;
            }
            const responseData = response.data;
            setTotalPages(responseData.count);
            setPrevious(responseData.previous);
            setNext(responseData.next);
            setDocs(responseData.results);
            setThumbnail(responseData.results.map(doc => doc.document_thumbnail));
            return true;
        } catch (error) {
            console.error('Error fetching documents:', error);
            return false;
        }
    };

    useEffect(() => {
        if (!Object.keys(defaultDocType).length > 0) {
            programmaticUpdate.current = true;
        }
        if (isFolderType) {
            setPaginationList([20, 40, 50]);
            setLimit(20);
        } else {
            setPaginationList([14, 20, 50]);
            setLimit(14);
        }
    }, [isFolderType]);


    const createParams = (aiMessage, tagsSearch = [], docTypeSearch = [], contentSearch = null) => {
        const query_params = {}
        query_params.docIds = docIds;
        query_params.docTypes = docTypeSearch;
        query_params.tadIDs = tagsSearch;
        query_params.contentSearch = contentSearch;
        query_params.aiMessage = aiMessage;
        if (tagsSearch.length > 0) {
            query_params.tags = tagsSearch.map(tag => tag.tag_id).join(',');
        }
        if (docTypeSearch.length > 0) {
            query_params.types = docTypeSearch.map(type => type.document_type).join(',');
        }
        if (contentSearch) {
            query_params.content_search = contentSearch;
        }
        query_params.latest = true;
        setParams(query_params);
    };

    useEffect(() => {
        if (programmaticUpdate.current) {
            programmaticUpdate.current = false;
            // console.log('Programmatic Update skipped:', limit);
        } else {
            const query_params = params;
            if (docIds.length > 0) {
                query_params.docIds = docIds;
                query_params.document_ids = docIds.join(',');
            }
            query_params.limit = limit;
            query_params.offset = (currentPage - 1) * limit;
            setParams(query_params);

            if (isFolderType) {
                query_params.root_folder_id = rootFolderId;
            } else {
                delete query_params.root_folder_id;
            }
            query_params.custom_fields = customFields.join(',');
            // console.warn("query_params", query_params, isFolderType);
            if (!Object.keys(defaultDocType).length > 0) {
                // console.warn("executed_query_params", query_params, isFolderType);
                fetchDocs('data/documents', query_params).then(success => setQuietMode(!success));
            }
        }
    }, [params, limit, currentPage, reloadDocs, docIds, rootFolderId]);


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDocBack = () => {
        setSelectDoc(null);
        setSelectDocId(null);
        setRedirect(false);
    };

    const handleDeleteOpen = (delDocId) => {
        setDeleteOpen(true);
        setDelDocumentID(delDocId);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const handleDocsReload = () => {
        setReloadDocs(!reloadDocs);
    }

    const deleteDocument = async (delDocumentId) => {
        setDeleteLoading([true, delDocumentId]);
        try {
            // console.warn(delDocumentId);
            const response = await api.delete(`data/documents?document_id=${delDocumentId}`);
            setDeleteOpen(false);
            if (response.status === 200) {
                handleDeleteClose();
                showSnackbar(t('document_deleted_successfully'));
                programmaticUpdate.current = false;
                handleDocsReload();
            } else {
                console.error('Failed to delete document');
            }
        } catch (err) {
            console.error(err);
        }
        setDeleteLoading([false, null])
    };


    const downloadPdf = async (doc) => {
        setDownloadLoading([true, doc.document_id]);
        try {
            const doc_id = doc.document_id;
            const response = await api.get(`data/documents?document_id=${doc_id}&file=true&download=true`, {
                responseType: 'arraybuffer'
            });
            const fileType = response.headers['content-type'] || 'application/octet-stream';
            const docBlob = new Blob([response.data], {type: fileType});
            const docDataUrl = URL.createObjectURL(docBlob);
            const link = document.createElement('a');
            link.href = docDataUrl;
            link.download = doc.document_super_name + doc.document_version_location.substring(doc.document_version_location.lastIndexOf('.'));
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(docDataUrl);
            document.body.removeChild(link);
            showSnackbar(t('document_downloaded_successfully'));
        } catch (err) {
            console.error(err);
        }
        setDownloadLoading([false, null]);
    };

    const handleLimitChange = (event) => {
        setLimit(Number(event.target.value));
        setCurrentPage(1);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackBarOpen(true);
    };

    const handleParentChange = (folderId) => {
        setRootFolderId(folderId);
    };

    useEffect(() => {
        const handleRedirect = () => {
            setSelectDoc(null);
            setSelectDocId(null);
            setRedirect(false);
        };
        eventEmitter.on('redirectToHome', handleRedirect);
        return () => {
            eventEmitter.off('redirectToHome', handleRedirect);
        };
    }, []);
    if (redirect && selectDoc) {
        return (
            <GridWrapper>
                <DisplayDoc doc={selectDoc} doc_id={selectDocId} handleDocBack={handleDocBack}/>
            </GridWrapper>
        );
    } else {
        return (
            <>
                <DeleteConfirmationDialog
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    onDelete={deleteDocument}
                    deleteLoading={deleteLoading[0]}
                    delDocumentId={delDocumentId}
                    deleteMessage={t('are_you_sure_you_want_to_delete_this_file')}
                />
                <Stack direction="row" sx={{height: '100vh'}}>
                    <Box sx={{
                        flex: 1,
                        padding: 2,
                        transition: 'margin 0.3s',  // Smooth transition when the drawer opens/closes
                    }}>
                        <Stack direction='column' sx={{height: '90vh'}}>
                            <Box sx={{paddingInline: 4}}>
                                <FilterContent
                                    createParams={createParams}
                                    showSnackbar={showSnackbar}
                                    handleFolderType={handleFolderType}
                                    handleItemView={handleItemView}
                                    isFolderType={isFolderType}
                                    params={params}
                                    clearDocIds={clearDocIds}
                                    handleDrawerChat={handleDrawerChat}
                                    openDrawerChat={openDrawerChat}
                                    programmaticUpdate={programmaticUpdate}
                                />
                            </Box>
                            <Divider/>
                            <Box sx={{flex: 1, overflowY: 'auto', padding: 3, position: 'relative'}}>
                                {(isLoading || docs.length < 1) ? (
                                    <Box sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        width: '100%'
                                    }}>
                                        <CircularProgress size={48} color="secondary"/>
                                    </Box>
                                ) : (
                                    isFolderType && 'document_ids' in docs[0] ? (
                                        <FolderMap
                                            docs={docs}
                                            customFields={customFields}
                                            rootFolderId={rootFolderId}
                                            downloadPdf={downloadPdf}
                                            downloadLoading={downloadLoading}
                                            handleItem={handleItem}
                                            handleDeleteOpen={handleDeleteOpen}
                                            deleteOpen={deleteOpen}
                                            handleDeleteClose={handleDeleteClose}
                                            deleteDocument={deleteDocument}
                                            deleteLoading={deleteLoading}
                                            handleParentChange={handleParentChange}
                                            handleCheckboxChange={handleCheckboxChange}
                                            selectedDocuments={selectedDocuments}
                                            handleDocsReload={handleDocsReload}
                                            folderNavigateTo={folderNavigateTo}
                                            folderPathSlicer={folderPathSlicer}
                                            folderPath={folderPath}
                                        />
                                    ) : (!isFolderType && !('document_ids' in docs[0])) ? (
                                        <>
                                            <Box display="flex" flexDirection="column" gap={1}>
                                                <Stack direction="row" spacing={1} alignItems="center" marginLeft={1}>
                                                    <Typography variant="h5" gutterBottom>{t('documents')}:</Typography>
                                                    <Typography variant="h6" gutterBottom> {totalPages}</Typography>
                                                </Stack>
                                            </Box>
                                            <ImageGridContainer
                                                params={params}
                                                docs={docs}
                                                customFields={customFields}
                                                handleItem={handleItem}
                                                downloadPdf={downloadPdf}
                                                downloadLoading={downloadLoading}
                                                handleDeleteOpen={handleDeleteOpen}
                                                deleteOpen={deleteOpen}
                                                handleDeleteClose={handleDeleteClose}
                                                deleteDocument={deleteDocument}
                                                deleteLoading={deleteLoading}
                                                handleCheckboxChange={handleCheckboxChange}
                                                selectedDocuments={selectedDocuments}
                                                openDrawerChat={openDrawerChat}
                                            />
                                            <CustomSnackbar open={snackbarOpen} message={snackbarMessage}
                                                            onClose={handleCloseSnackbar}/>
                                        </>
                                    ) : (
                                        <Box>
                                            <Typography variant="h6" align="center">No documents found</Typography>
                                        </Box>
                                    )
                                )}
                            </Box>
                            <Divider/>
                            <Box sx={{p: 2, display: 'flex', justifyContent: 'center', marginTop: 'auto'}}>
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                    handleLimitChange={handleLimitChange}
                                    limit={limit}
                                    perPageCount={paginationList}
                                />
                            </Box>
                        </Stack>

                    </Box>
                    <Box sx={{
                        width: openDrawerChat ? '35vw' : '0',
                        flexShrink: 0,
                        transition: 'width 0.3s ease',
                        overflow: 'hidden',
                    }}>
                        <ChatDrawer
                            open={openDrawerChat}
                            messages={messages}
                            message={message}
                            setMessage={setMessage}
                            handleKeyPress={handleKeyPress}
                            handleSearch={handleSearch}
                            chatContentRef={chatContentRef}
                            handleDrawerChat={handleDrawerChat}
                            aiLoading={aiLoading}
                            handleDocumentClick={handleDocumentClick}
                        />
                    </Box>
                </Stack>
            </>
        );
    }
};