import React from 'react';
import { Menu, MenuItem } from '@mui/material';

const ContextMenu = ({ anchorEl, open, onClose, onMenuAction }) => {
    const handleMenuItemClick = (action) => {
        onMenuAction(action);
        onClose();
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
        >
            <MenuItem onClick={() => handleMenuItemClick('cut')}>Cut</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('copy')}>Copy</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('paste')}>Paste</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('sendTo')}>Send To</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('rename')}>Rename</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('delete')}>Delete</MenuItem>
        </Menu>
    );
};

export default ContextMenu;
