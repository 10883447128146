import React, {useEffect} from 'react';

const GoogleDrivePicker = ({accessToken, onFolderSelected}) => {
    const showPicker = () => {
        if (!window.google) return;

        // Apply high z-index styling
        const style = document.createElement('style');
        style.innerHTML = `
            .picker-dialog { z-index: 9999 !important; }
        `;
        document.head.appendChild(style);

        const view = new window.google.picker.DocsView(window.google.picker.ViewId.FOLDERS)
            .setSelectFolderEnabled(true)
            .setMimeTypes('application/vnd.google-apps.folder');

        const picker = new window.google.picker.PickerBuilder()
            .setOAuthToken(accessToken)
            .addView(view)
            .setCallback(pickerCallback)
            .build();

        picker.setVisible(true);
    };

    const pickerCallback = (data) => {
        if (data.action === window.google.picker.Action.PICKED) {
            const folderId = data.docs[0].id;
            onFolderSelected(folderId);
        }
    };

    useEffect(() => {
        const loadPickerApi = () => {
            const script = document.createElement('script');
            script.src = 'https://apis.google.com/js/api.js';
            script.onload = () => {
                window.gapi.load('picker', showPicker);
            };
            document.body.appendChild(script);
        };

        if (accessToken) {
            loadPickerApi();
        }
    }, [accessToken]);

    return null;
};

export default GoogleDrivePicker;
