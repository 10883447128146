import React from "react";
import PropTypes from 'prop-types';
import {Box, styled, Tooltip, tooltipClasses} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import Typography from "@mui/material/Typography";


const CustomTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#d8d8d8',
        color: '#674f87',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));


const Folder = ({folderID, folderName, folderNavigateTo, onRightClick, truncateLength = 12}) => {

    const handleDoubleClick = () => {
        folderNavigateTo(folderID, folderName);
    };

    /*    const handleSingleClick = () => {
            // Implement single-click navigation if necessary
            console.warn("Folder single-clicked:", folderName);
        };*/

    const truncateName = (name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    };

    return (
        <Box
            tabIndex={0}
            onDoubleClick={handleDoubleClick}
            // onClick={handleSingleClick}
            onContextMenu={(event) => onRightClick(event, {folderName, folderID})}
            sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '1px',
                borderRadius: '4px',
                '&:hover': {
                    backgroundColor: '#fcdce0',
                    transition: 'background-color 0.3s',
                },
            }}
            aria-label={`Folder: ${folderName}`} // Accessibility feature
        >
            <CustomTooltip title={folderName}>
                <Box
                    sx={{
                        display: 'flex',
                        width: '200px',
                        alignItems: 'center',
                        borderRadius: '4px',
                    }}
                >
                    <FolderIcon sx={{color: '#ef798a', fontSize: '64px'}}/>
                    <Typography
                        variant="body1"
                        sx={{
                            marginInline: '8px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            maxWidth: '120px',
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                            maxHeight: '4em',
                            overflowY: 'auto',
                        }}
                    >
                        {truncateName(folderName, truncateLength)}
                    </Typography>
                </Box>
            </CustomTooltip>
        </Box>
    );
};
export default Folder;