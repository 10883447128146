import React, {useState, useEffect} from 'react';
import Navbar from './components/Navbar/Navbar';
import {Outlet, useNavigate} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import TopMenu from './components/TopMenu/TopMenu';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Login from './pages/Login/Login';
import {ProgressProvider} from './contexts/ProgressContext';
import ProgressWidget from './components/Progress/ProgressWidget';
// import useTokenExpirationCheck from './hooks/TokenExpirationCheck';
import {useMediaQuery} from '@mui/material';
import {useAuth} from "./contexts/AuthContext";


function App({isLoggedIn, handleLogin}) {
    const navigate = useNavigate();
    const {user} = useAuth();

    useEffect(() => {
        if (!user || !isLoggedIn) {
            navigate('/login');
        }
    }, [user, navigate, isLoggedIn]);

    const isSmallerScreen = useMediaQuery('(max-width:1000px), (max-height:600px)');
    const isMediumScreen = useMediaQuery('(min-width:1000px) and (max-width:1500px)');
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    React.useEffect(() => {
        if (isMediumScreen) {
            setIsCollapsed(true);
        } else {
            setIsCollapsed(false);
        }
    }, [isSmallerScreen, isMediumScreen]);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    // useTokenExpirationCheck();
    const [title, setTitle] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const parsedTitle = location.pathname.replace(/\W/g, ' ');
        setTitle(parsedTitle);
    }, [location]);

    /* useEffect(() => {
         if (!isLoggedIn) {
             navigate('/login');
         }
     }, [isLoggedIn, navigate]);*/

    // const handleLogin = () => {
    //     setIsLoggedIn(true);
    // };

    return (
        <Box>
            {isLoggedIn && user ? (
                <ProgressProvider>
                    <Box sx={{display: 'flex'}}>
                        <CssBaseline/>
                        <TopMenu toggleDrawer={toggleDrawer} toggleCollapse={toggleCollapse}/>
                        <Navbar isDrawerOpen={isDrawerOpen} isCollapsed={isCollapsed} toggleDrawer={toggleDrawer}/>
                        <Box component="main" sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            // paddingLeft: isSmallerScreen ? 1 : 10,
                            // paddingRight: isSmallerScreen ? 1 : 0,
                            height: 'calc(100vh - 50px)',
                            width: 'calc(100vw - 10px)',
                        }}>
                            <Toolbar/>
                            <Outlet/>
                        </Box>
                    </Box>
                    <ProgressWidget/>
                </ProgressProvider>
            ) : (
                <Login onLogin={handleLogin}/>
            )}
        </Box>
    );
}

export default App;
