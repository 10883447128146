import React, {useEffect, useState, useMemo, useCallback} from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import {
    Box,
    Button,
    Tooltip,
    styled,
    tooltipClasses,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import DocumentThumbnail from "../../components/Document/DocumentThumbnail";
import {useTranslation} from 'react-i18next';
import ContextMenu from '../ContextMenu/ContextMenu';
import FolderRender from "./FolderRender";

const CustomTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#d8d8d8',
        color: '#674f87',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));


const FolderView = ({
                        docs,
                        customFields,
                        rootFolderId,
                        handleItem,
                        downloadPdf,
                        downloadLoading,
                        handleDeleteOpen,
                        deleteOpen,
                        handleDeleteClose,
                        deleteDocument,
                        deleteLoading,
                        handleParentChange,
                        handleCheckboxChange,
                        selectedDocuments,
                        handleDocsReload,
                        folderNavigateTo,
                        folderPathSlicer,
                        folderPath,
                    }) => {
    const {t} = useTranslation();

    const [newData, setNewData] = useState({
        operation: '',
        folder_id: 0,
        folder_name: '',
        document_id: 0,
        document_name: ''
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [rename, setRename] = useState('');
    const [createFolderDialogOpen, setCreateFolderDialogOpen] = useState(false);
    const [newFolder, setNewFolder] = useState('');
    const [operationQueue, setOperationQueue] = useState([]);

    const handleCreateFolderClick = () => {
        setCreateFolderDialogOpen(true);
    };

    const handleCreateFolderSave = () => {
        const newFolderObject = {document_ids: '', 
                                folder_id: 9999, 
                                folder_name: newFolder, 
                                parent_folder_id: folderPath.length > 1 ? folderPath[folderPath.length - 1].folderID : folderPath[0].folderID,
                                parent_folder_name: folderPath.length > 1 ? folderPath[folderPath.length - 1].folderName : folderPath[0].folderName           
             };
        docs.push(newFolderObject);
        // handleDocsReload(true);
        setCreateFolderDialogOpen(false);
        setNewFolder('');
        renderContents(docs);
    };

    useEffect( () => {
        console.warn("NewData", newData);
    },[newData]);


    const handleRightClick = (event, item) => {
        event.preventDefault();
        setSelectedItem(item);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuAction = (action) => {
        switch (action) {
            case 'cut':
                console.log('Cut', selectedItem);
                break;
            case 'copy':
                setNewData((prevData) => ({
                    ...prevData,
                    operation: 'copy',
                    document_id: selectedItem.document_id,
                    document_name: selectedItem.document_super_name,
                    folder_id: selectedItem.folderID,
                    folder_name: selectedItem.folderName,
                }));
                operationQueue.push(newData);
                console.log('Copy', selectedItem);
                break;
            case 'paste':
                console.log('Pasted');
                break;
            case 'sendTo':
                console.log('Send To', selectedItem);
                break;
            case 'rename':
                setRename(selectedItem);
                setRenameDialogOpen(true);
                break;
            case 'delete':
                console.log('Delete', selectedItem);
                break;
            default:
                break;
        }
    };

    const handlePaste = () => {
        if (newData.operation === "copy") {
            const pastedItem = {
                ...newData,
                folder_id: selectedItem.folderID,
                folder_name: selectedItem.folderName
            };
            docs.push(pastedItem);
            console.log('Pasted item', pastedItem);
            setNewData((prevData) => ({ ...prevData, operation: '' }));
        } else {
            console.log('Nothing to paste');
        }
    }

    const handleRenameClose = () => {
        setRenameDialogOpen(false);
    };

    const handleRenameSave = () => {
        setNewData((prevData) => ({
            ...prevData,
            subfolder_name: rename,
        }));
        handleRenameClose();
    };

    const changeParent = (folderID) => {
        handleParentChange(folderID);
        folderPathSlicer(folderID);
    }

    // useEffect(() => {
    //     handleDocsReload(true);
    // }, [folderLevel]);


    const renderContents = (docs) => {
        return (
            docs.length > 0 ?
                (<FolderRender
                    docs={docs}
                    customFields={customFields}
                    rootFolderId={rootFolderId}
                    folderNavigateTo={folderNavigateTo}
                    handleItem={handleItem}
                    downloadPdf={downloadPdf}
                    downloadLoading={downloadLoading}
                    handleDeleteOpen={handleDeleteOpen}
                    deleteOpen={deleteOpen}
                    handleDeleteClose={handleDeleteClose}
                    deleteDocument={deleteDocument}
                    deleteLoading={deleteLoading}
                    handleCheckboxChange={handleCheckboxChange}
                    selectedDocuments={selectedDocuments}
                    handleRightClick={handleRightClick}
                />) : ("")
        );
    };

    const handleBreadCrumb = (event) => {
        event.preventDefault();
    };

    const truncateName = useCallback((name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    }, []);
    return (
        <GridWrapper sx={{paddingTop: '10px'}}>
            <Box
                sx={{
                    marginTop: 2,
                    marginBottom: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    width: '80%',
                    background: '#ffffff',
                }}
            >
                {/*{folderPath.length > 0 && (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => changeParent()}
                        color="secondary"
                        startIcon={<ArrowLeftIcon/>}
                    >
                        {t('back')}
                    </Button>
                )}*/}

                {folderPath.length > 0 && (
                    <Box onClick={handleBreadCrumb}>
                        <Breadcrumbs aria-label="breadcrumb" sx={{fontWeight: 'bold', color: '#ef798a'}}>
                            <Link
                                underline="hover"
                                color="inherit"
                                onClick={() => changeParent(0)}
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#674f87',
                                    '&:hover': {color: '#ef798a'},
                                    cursor: 'pointer',
                                    margin: '0 8px',
                                }}
                            >
                                {t('documents')}
                            </Link>
                            {folderPath.map((folder) => (
                                <CustomTooltip title={folder.folderName} key={folder.folderID}>
                                    <Link
                                        underline="hover"
                                        color="inherit"
                                        onClick={() => changeParent(folder.folderID)}
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#674f87',
                                            '&:hover': {color: '#ef798a'},
                                            cursor: 'pointer',
                                            margin: '0 8px',
                                        }}
                                    >
                                        {truncateName(folder.folderName, 12)}
                                    </Link>
                                </CustomTooltip>
                            ))}
                        </Breadcrumbs>
                    </Box>
                )}
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                >
                    Save Changes
                </Button>
                {folderPath.length > 0 && (
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={handleCreateFolderClick}
                    >
                        Create Folder
                    </Button>
                )}
                {folderPath.length > 0 && (
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={handlePaste}
                    >
                        Paste
                    </Button>
                )}
            </Box>

            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, overflowInline: 'hidden', marginTop: 2}}>
                {renderContents(docs)}
            </Box>

            <Dialog open={renameDialogOpen} onClose={handleRenameClose}>
                <DialogTitle>Rename Subfolder</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label='New Subfolder Name'
                        fullWidth
                        value={rename}
                        onChange={(e) => setRename(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRenameClose}>Cancel</Button>
                    <Button onClick={handleRenameSave} color="primary">Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={createFolderDialogOpen} onClose={() => setCreateFolderDialogOpen(false)}>
                <DialogTitle>Create New Folder</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label='Folder Name'
                        fullWidth
                        value={newFolder}
                        onChange={(e) => setNewFolder(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCreateFolderDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleCreateFolderSave}>Create</Button>
                </DialogActions>
            </Dialog>

            <ContextMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                onMenuAction={handleMenuAction}
            />
        </GridWrapper>
    );
};

export default FolderView;
