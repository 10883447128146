import {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GradientIcon from "../../pages/AR-Dashboard/grad-icon";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import Delete from "@mui/icons-material/Delete";
import ButtonGroup from "@mui/material/ButtonGroup";
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from "../Buttons/LoadingButton";
import {useAuth} from "../../contexts/AuthContext";
import api from "../../API";

const DocumentThumbnail = (documentArgs) => {
    const [documentData, setDocumentData] = useState(documentArgs.document || null);
    const fetchVersion = async (params) => {
        try {
            const response = await api.get('data/documents', {params});
            if (response.status !== 200) {
                console.error('Files loading error', response.status);
                return false;
            }
            const versionsData = response.data.results;
            // console.warn(response.data.results);
            setDocumentData(versionsData[0]);
            return true;
        } catch (error) {
            console.error('Error fetching Versions:', error);
            return false;
        }
    };

    useEffect(() => {
        if (!documentArgs.document && documentArgs.documentId) {
            const query_params = {
                document_id: documentArgs.documentId,
                custom_fields: documentArgs.customFields.join(','),
            };
            fetchVersion(query_params);
        }
    }, [documentArgs]);

    const {isUser, isMaintainer, isAdmin, isSuperuser} = useAuth();
    const truncateName = (name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    };
    return (
        documentData && documentData.document_id &&
        (<Card key={documentData.document_id}
              sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  background: '#f1f1f1',
                  transition: 'box-shadow 0.3s ease-in-out',
                  ':hover': {boxShadow: 5,}
              }}
              onContextMenu={(event) => documentArgs.onRightClick(event, documentData)}
            >
            <Checkbox
                sx={{
                    color: '#A6A6A6',
                    '&.Mui-checked': {
                        color: 'black',
                    },
                    '& .MuiSvgIcon-root': {
                        fontSize: 20,
                    },
                }}
                style={{position: 'absolute', left: '-4px', top: '-4px'}}
                checked={documentArgs.selectedDocuments.some(temp => documentData.document_id === temp.document_id)}
                onChange={() => documentArgs.handleCheckboxChange(documentData)}
                color="primary"
                inputProps={{'aria-label': 'select document'}}
            />
            <CardMedia
                component="img"
                image={documentData.document_thumbnail ? documentData.document_thumbnail : ''}
                sx={{
                    width: '90%',
                    height: 150,
                    backgroundSize: 'cover',
                    cursor: 'pointer',
                    marginTop: 2,
                }}
                onClick={() => documentArgs.handleItem(documentData)}
            />
            <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 1
            }}>
                <Typography
                    variant="subtitle2"
                    title={documentData.document_sub_name}
                    sx={{
                        whiteSpace: 'nowrap',     // Prevents text from wrapping to a new line
                        overflow: 'hidden',       // Hides overflow when text exceeds container width
                        textOverflow: 'ellipsis', // Adds '...' at the end if text is too long
                    }}
                >
                    {truncateName(documentData.document_sub_name, 20)}
                </Typography>
            </CardContent>
            <CardActions>
                <ButtonGroup
                    aria-label="Basic button group"
                    color="secondary"
                    size='small'
                    sx={{
                        background: '#fafafa',
                        marginBottom: '8px',
                    }}
                >
                    <Button
                        onClick={() => documentArgs.handleItem(documentData)}
                    >
                        <GradientIcon IconComponent={VisibilityIcon}/>
                    </Button>

                    <LoadingButton
                        isLoading={documentArgs.downloadLoading[0] && (documentArgs.downloadLoading[1] === documentData.document_id)}
                        onClick={() => documentArgs.downloadPdf(documentData)}
                        circleSize={20}
                        circleColor={'#220303'}
                        startIcon={<GradientIcon IconComponent={DownloadIcon}/>}
                        disabled={(documentArgs.downloadLoading[0] && (documentArgs.downloadLoading[1] === documentData.document_id)) || !(isAdmin || isMaintainer || isSuperuser)}
                    />

                    <LoadingButton
                        isLoading={documentArgs.deleteLoading[0] && (documentArgs.deleteLoading[1] === documentData.document_id)}
                        onClick={() => documentArgs.handleDeleteOpen(documentData.document_id)}
                        circleSize={20}
                        circleColor={'#220303'}
                        startIcon={<GradientIcon IconComponent={Delete}/>}
                        disabled={(documentArgs.deleteLoading[0] && (documentArgs.deleteLoading[1] === documentData.document_id)) || !(isAdmin || isSuperuser)}
                        variant="outlined"
                        size="small"
                        color="secondary"
                    />
                </ButtonGroup>
            </CardActions>
        </Card>)
    );
};

export default DocumentThumbnail;
